define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-logs", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object"], function (_exports, _ajax, _ajaxError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomWizardLogs = _object.default.extend();
  const logItemTypes = {
    date: "date_time",
    action: "text",
    message: "long_text",
    user: "user",
    username: "text"
  };
  function logItem(item, attr) {
    return {
      value: item[attr],
      type: logItemTypes[attr]
    };
  }
  CustomWizardLogs.reopenClass({
    list(wizardId) {
      let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let data = {
        page
      };
      return (0, _ajax.ajax)(`/admin/wizards/logs/${wizardId}`, {
        data
      }).catch(_ajaxError.popupAjaxError).then(result => {
        if (result.logs) {
          result.logs = result.logs.map(item => {
            let map = {};
            if (item.date) {
              map.date = logItem(item, "date");
            }
            if (item.action) {
              map.action = logItem(item, "action");
            }
            if (item.user) {
              map.user = item.user;
            } else {
              map.user = logItem(item, "username");
            }
            if (item.message) {
              map.message = logItem(item, "message");
            }
            return map;
          });
        }
        return result;
      });
    }
  });
  var _default = _exports.default = CustomWizardLogs;
});