define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-group-selector", ["exports", "select-kit/components/combo-box", "@ember/object", "discourse-common/lib/helpers"], function (_exports, _comboBox, _object, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    content: (0, _object.computed)("groups.[]", "field.content.[]", function () {
      const whitelist = (0, _helpers.makeArray)(this.field.content);
      return this.groups.filter(group => {
        return !whitelist.length || whitelist.indexOf(group.id) > -1;
      }).map(g => {
        return {
          id: g.id,
          name: g.full_name ? g.full_name : g.name
        };
      });
    })
  });
});