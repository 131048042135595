define("discourse/plugins/discourse-custom-wizard/discourse/routes/custom-wizard-step", ["exports", "I18n", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "@ember/routing/route", "discourse/mixins/scroll-top", "@ember/object", "@ember/service"], function (_exports, _I18n, _customWizard, _route, _scrollTop, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    beforeModel() {
      const wizard = (0, _customWizard.getCachedWizard)();
      this.set("wizard", wizard);
      if (!wizard || !wizard.permitted || wizard.completed) {
        this.router.replaceWith("customWizard");
      }
    },
    model(params) {
      const wizard = this.wizard;
      if (wizard && wizard.steps) {
        const step = wizard.steps.findBy("id", params.step_id);
        return step ? step : wizard.steps[0];
      } else {
        return wizard;
      }
    },
    afterModel(model) {
      if (model.completed) {
        return this.router.transitionTo("wizard.index");
      }
      return model.set("wizardId", this.wizard.id);
    },
    setupController(controller, model) {
      let props = {
        step: model,
        wizard: this.wizard
      };
      if (!model.permitted) {
        props["stepMessage"] = {
          state: "not-permitted",
          text: model.permitted_message || _I18n.default.t("wizard.step_not_permitted")
        };
        if (model.index > 0) {
          props["showReset"] = true;
        }
      }
      controller.setProperties(props);
    },
    didTransition() {
      (0, _scrollTop.scrollTop)();
      return true;
    }
  }, [["method", "didTransition", [_object.action]]]));
});