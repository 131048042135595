define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-custom-step", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "wizard-custom-step",
    stepConditionOptions(stepIndex) {
      const options = {
        inputTypes: "validation",
        context: "step",
        textSelection: "value",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options["wizardFieldSelection"] = true;
        options["wizardActionSelection"] = true;
      }
      return options;
    },
    actions: {
      bannerUploadDone(upload) {
        this.setProperties({
          "step.banner": upload.url,
          "step.banner_upload_id": upload.id
        });
      },
      bannerUploadDeleted() {
        this.setProperties({
          "step.banner": null,
          "step.banner_upload_id": null
        });
      }
    }
  }, [["method", "stepConditionOptions", [(0, _decorators.default)("step.index")]]]));
});