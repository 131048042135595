define("discourse/plugins/discourse-custom-wizard/discourse/connectors/category-custom-settings/custom-wizard-category-settings", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin", "discourse/lib/ajax-error"], function (_exports, _customWizardAdmin, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      _customWizardAdmin.default.all().then(result => {
        component.set("wizardList", result);
      }).catch(_ajaxError.popupAjaxError);
      component.set("wizardListVal", attrs?.category?.custom_fields?.create_topic_wizard);
    },
    actions: {
      changeWizard(wizard) {
        this.set("wizardListVal", wizard);
        this.set("category.custom_fields.create_topic_wizard", wizard);
      }
    }
  };
});