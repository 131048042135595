define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-submissions-show", ["exports", "@ember/array", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin", "discourse/routes/discourse", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-submission", "@ember/service"], function (_exports, _array, _customWizardAdmin, _discourse, _wizardSubmission, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    model(params) {
      return _customWizardAdmin.default.submissions(params.wizardId);
    },
    afterModel(model) {
      if (model === null) {
        return this.router.transitionTo("adminWizardsSubmissions");
      }
    },
    setupController(controller, model) {
      const {
        fields,
        submissions
      } = (0, _wizardSubmission.formatModel)(model);
      controller.setProperties({
        wizard: model.wizard,
        fields: (0, _array.A)(fields),
        submissions: (0, _array.A)(submissions),
        total: model.total
      });
    }
  });
});