define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-custom-action", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/mixins/undo-changes", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _wizard, _object, _undoChanges, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_undoChanges.default, dt7948.p({
    componentType: "action",
    classNameBindings: [":wizard-custom-action", "visible"],
    visible: (0, _object.computed)("currentActionId", function () {
      return this.action.id === this.currentActionId;
    }),
    createTopic: (0, _computed.equal)("action.type", "create_topic"),
    updateProfile: (0, _computed.equal)("action.type", "update_profile"),
    watchCategories: (0, _computed.equal)("action.type", "watch_categories"),
    watchTags: (0, _computed.equal)("action.type", "watch_tags"),
    sendMessage: (0, _computed.equal)("action.type", "send_message"),
    openComposer: (0, _computed.equal)("action.type", "open_composer"),
    sendToApi: (0, _computed.equal)("action.type", "send_to_api"),
    addToGroup: (0, _computed.equal)("action.type", "add_to_group"),
    routeTo: (0, _computed.equal)("action.type", "route_to"),
    createCategory: (0, _computed.equal)("action.type", "create_category"),
    createGroup: (0, _computed.equal)("action.type", "create_group"),
    apiEmpty: (0, _computed.empty)("action.api"),
    groupPropertyTypes: (0, _wizard.selectKitContent)(["id", "name"]),
    hasCustomFields: (0, _computed.or)("basicTopicFields", "updateProfile", "createGroup", "createCategory"),
    basicTopicFields: (0, _computed.or)("createTopic", "sendMessage", "openComposer"),
    publicTopicFields: (0, _computed.or)("createTopic", "openComposer"),
    showPostAdvanced: (0, _computed.or)("createTopic", "sendMessage"),
    availableNotificationLevels: _wizard.notificationLevels.map(type => {
      return {
        id: type,
        name: _I18n.default.t(`admin.wizard.action.watch_x.notification_level.${type}`)
      };
    }),
    messageUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/action-settings",
    messageKey(type) {
      let key = "type";
      if (type) {
        key = "edit";
      }
      return key;
    },
    customFieldsContext(type) {
      return `action.${type}`;
    },
    runAfterContent(steps) {
      let content = steps.map(function (step) {
        return {
          id: step.id,
          name: step.title || step.id
        };
      });
      content.unshift({
        id: "wizard_completion",
        name: _I18n.default.t("admin.wizard.action.run_after.wizard_completion")
      });
      return content;
    },
    availableApis(apis) {
      return apis.map(a => {
        return {
          id: a.name,
          name: a.title
        };
      });
    },
    availableEndpoints(apis, api) {
      if (!api) {
        return [];
      }
      return apis.find(a => a.name === api).endpoints;
    },
    hasEventField(fieldTypes) {
      return fieldTypes.map(ft => ft.id).includes("event");
    },
    hasLocationField(fieldTypes) {
      return fieldTypes.map(ft => ft.id).includes("location");
    }
  }, [["method", "messageKey", [(0, _decorators.default)("action.type")]], ["method", "customFieldsContext", [(0, _decorators.default)("action.type")]], ["method", "runAfterContent", [(0, _decorators.default)("wizard.steps")]], ["method", "availableApis", [(0, _decorators.default)("apis")]], ["method", "availableEndpoints", [(0, _decorators.default)("apis", "action.api")]], ["method", "hasEventField", [(0, _decorators.default)("fieldTypes")]], ["method", "hasLocationField", [(0, _decorators.default)("fieldTypes")]]]));
});