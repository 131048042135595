define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-topic", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    topics: [],
    didInsertElement() {
      const value = this.field.value;
      if (value) {
        this.set("topics", value);
      }
    },
    actions: {
      setValue(_, topics) {
        if (topics.length) {
          this.set("field.value", topics);
        }
      }
    }
  });
});