define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-date-input", ["exports", "discourse/components/date-input", "discourse-common/utils/decorators"], function (_exports, _dateInput, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dateInput.default.extend(dt7948.p({
    useNativePicker: false,
    classNameBindings: ["fieldClass"],
    placeholder() {
      return this.format;
    },
    _opts() {
      return {
        format: this.format || "LL"
      };
    }
  }, [["method", "placeholder", [(0, _decorators.default)()]]]));
});