define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-upload", ["exports", "discourse/mixins/uppy-upload", "@ember/component", "@ember/object"], function (_exports, _uppyUpload, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_uppyUpload.default, {
    classNames: ["wizard-field-upload"],
    classNameBindings: ["isImage", "fieldClass"],
    uploading: false,
    type: (0, _object.computed)(function () {
      return `wizard_${this.field.id}`;
    }),
    id: (0, _object.computed)(function () {
      return `wizard_field_upload_${this.field.id}`;
    }),
    isImage: (0, _object.computed)("field.value.extension", function () {
      return this.field.value && this.siteSettings.wizard_recognised_image_upload_formats.split("|").includes(this.field.value.extension);
    }),
    uploadDone(upload) {
      this.set("field.value", upload);
    }
  });
});