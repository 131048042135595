define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-selector-type", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "a",
    classNameBindings: ["active"],
    active(type, activeType) {
      return type === activeType;
    },
    click() {
      this.toggle(this.item.type);
    }
  }, [["method", "active", [(0, _decorators.default)("item.type", "activeType")]]]));
});