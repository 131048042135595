define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-manager", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "discourse-common/lib/get-url"], function (_exports, _ajax, _ajaxError, _object, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomWizardManager = _object.default.extend();
  const basePath = "admin/wizards/manager";
  CustomWizardManager.reopenClass({
    import($formData) {
      return (0, _ajax.ajax)(`/${basePath}/import`, {
        type: "POST",
        data: $formData,
        processData: false,
        contentType: false
      }).catch(_ajaxError.popupAjaxError);
    },
    export(wizardIds) {
      let url = `${(0, _getUrl.default)()}/${basePath}/export?`;
      wizardIds.forEach((wizardId, index) => {
        let step = "wizard_ids[]=" + wizardId;
        if (index !== wizardIds[wizardIds.length - 1]) {
          step += "&";
        }
        url += step;
      });
      location.href = url;
    },
    destroy(wizardIds) {
      return (0, _ajax.ajax)(`/${basePath}/destroy`, {
        type: "DELETE",
        data: {
          wizard_ids: wizardIds
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = CustomWizardManager;
});