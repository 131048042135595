define("discourse/plugins/discourse-custom-wizard/discourse/components/validator", ["exports", "@ember/component", "@ember/object/computed", "discourse/lib/ajax"], function (_exports, _component, _computed, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["validator"],
    classNameBindings: ["isValid", "isInvalid"],
    validMessageKey: null,
    invalidMessageKey: null,
    isValid: null,
    isInvalid: (0, _computed.equal)("isValid", false),
    init() {
      this._super(...arguments);
      if (this.get("validation.backend")) {
        // set a function that can be called as often as it need to
        // from the derived component
        this.backendValidate = params => {
          return (0, _ajax.ajax)("/realtime-validations", {
            data: {
              type: this.get("type"),
              ...params
            }
          });
        };
      }
    },
    didInsertElement() {
      this.appEvents.on("custom-wizard:validate", this, this.checkIsValid);
    },
    willDestroyElement() {
      this.appEvents.off("custom-wizard:validate", this, this.checkIsValid);
    },
    checkIsValid() {
      this.set("isValid", this.validate());
    }
  });
});