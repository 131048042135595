define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-date", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    setValue() {
      this.set("field.value", this.date.format(this.field.format));
    },
    actions: {
      onChange(value) {
        this.set("date", moment(value));
      }
    }
  }, [["method", "setValue", [(0, _decorators.observes)("date")]]]));
});