define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-submissions-show", ["exports", "@ember/controller", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/lib/computed", "@ember/service", "discourse/plugins/discourse-custom-wizard/discourse/components/modal/admin-wizards-columns", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-submission"], function (_exports, _controller, _computed, _decorators, _computed2, _service, _adminWizardsColumns, _customWizardAdmin, _wizardSubmission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modal: (0, _service.inject)(),
    downloadUrl: (0, _computed2.fmt)("wizard.id", "/admin/wizards/submissions/%@/download"),
    noResults: (0, _computed.empty)("submissions"),
    page: 0,
    total: 0,
    loadMoreSubmissions() {
      const page = this.get("page");
      const wizardId = this.get("wizard.id");
      this.set("loadingMore", true);
      _customWizardAdmin.default.submissions(wizardId, page).then(result => {
        if (result.submissions) {
          const {
            submissions
          } = (0, _wizardSubmission.formatModel)(result);
          this.get("submissions").pushObjects(submissions);
        }
      }).finally(() => {
        this.set("loadingMore", false);
      });
    },
    displaySubmissions(submissions, fields) {
      let result = [];
      submissions.forEach(submission => {
        let sub = {};
        Object.keys(submission).forEach(fieldId => {
          if (fields.some(f => f.id === fieldId && f.enabled)) {
            sub[fieldId] = submission[fieldId];
          }
        });
        result.push(sub);
      });
      return result;
    },
    actions: {
      loadMore() {
        if (!this.loadingMore && this.submissions.length < this.total) {
          this.set("page", this.get("page") + 1);
          this.loadMoreSubmissions();
        }
      },
      showEditColumnsModal() {
        return this.modal.show(_adminWizardsColumns.default, {
          model: {
            columns: this.get("fields"),
            reset: () => {
              this.get("fields").forEach(field => {
                field.set("enabled", true);
              });
            }
          }
        });
      }
    }
  }, [["method", "displaySubmissions", [(0, _decorators.default)("submissions.[]", "fields.@each.enabled")]]]));
});