define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-input", ["exports", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "@ember/component", "discourse-common/utils/decorators", "@ember/array"], function (_exports, _object, _computed, _wizardMapper, _component, _decorators, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":mapper-input", "inputType"],
    inputType: (0, _computed.alias)("input.type"),
    isConditional: (0, _computed.equal)("inputType", "conditional"),
    isAssignment: (0, _computed.equal)("inputType", "assignment"),
    isAssociation: (0, _computed.equal)("inputType", "association"),
    isValidation: (0, _computed.equal)("inputType", "validation"),
    hasOutput: (0, _computed.or)("isConditional", "isAssignment"),
    hasPairs: (0, _computed.or)("isConditional", "isAssociation", "isValidation"),
    canAddPair: (0, _computed.not)("isAssignment"),
    connectors: (0, _object.computed)(function () {
      return (0, _wizardMapper.connectorContent)("output", this.input.type, this.options);
    }),
    inputTypes: (0, _object.computed)(function () {
      return (0, _wizardMapper.inputTypesContent)(this.options);
    }),
    setupType() {
      if (this.hasPairs && (!this.input.pairs || this.input.pairs.length < 1)) {
        this.send("addPair");
      }
      if (this.hasOutput) {
        this.set("input.output", null);
        if (!this.input.outputConnector) {
          const options = this.options;
          this.set("input.output_type", (0, _wizardMapper.defaultSelectionType)("output", options));
          this.set("input.output_connector", (0, _wizardMapper.defaultConnector)("output", this.inputType, options));
        }
      }
    },
    actions: {
      addPair() {
        if (!this.input.pairs) {
          this.set("input.pairs", (0, _array.A)());
        }
        const pairs = this.input.pairs;
        const pairCount = pairs.length + 1;
        pairs.forEach(p => (0, _object.set)(p, "pairCount", pairCount));
        pairs.pushObject((0, _wizardMapper.newPair)(this.input.type, Object.assign({}, this.options, {
          index: pairs.length,
          pairCount
        })));
      },
      removePair(pair) {
        const pairs = this.input.pairs;
        const pairCount = pairs.length - 1;
        pairs.forEach(p => (0, _object.set)(p, "pairCount", pairCount));
        pairs.removeObject(pair);
      }
    }
  }, [["method", "setupType", [(0, _decorators.observes)("input.type")]]]));
});