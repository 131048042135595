define("discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription", ["exports", "@ember/object/mixin", "@ember/application", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _mixin, _application, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PRODUCT_PAGE = "https://custom-wizard.pavilion.tech/pricing";
  const SUPPORT_MESSAGE = "https://coop.pavilion.tech/new-message?username=support&title=Custom%20Wizard%20Support";
  const MANAGER_CATEGORY = "https://pavilion.tech/products/discourse-custom-wizard-plugin/support";
  var _default = _exports.default = _mixin.default.create(dt7948.p({
    subscriptionLandingUrl: PRODUCT_PAGE,
    subscriptionClientUrl: "/admin/plugins/subscription-client",
    adminWizards() {
      return (0, _application.getOwner)(this).lookup("controller:admin-wizards");
    },
    subscribed: (0, _computed.readOnly)("adminWizards.subscribed"),
    subscriptionType: (0, _computed.readOnly)("adminWizards.subscriptionType"),
    businessSubscription: (0, _computed.readOnly)("adminWizards.businessSubscription"),
    communitySubscription: (0, _computed.readOnly)("adminWizards.communitySubscription"),
    standardSubscription: (0, _computed.readOnly)("adminWizards.standardSubscription"),
    subscriptionAttributes: (0, _computed.readOnly)("adminWizards.subscriptionAttributes"),
    subscriptionClientInstalled: (0, _computed.readOnly)("adminWizards.subscriptionClientInstalled"),
    subscriptionLink(subscriptionClientInstalled) {
      return subscriptionClientInstalled ? this.subscriptionClientUrl : this.subscriptionLandingUrl;
    },
    subscriptionCtaLink(subscriptionType) {
      switch (subscriptionType) {
        case "none":
          return PRODUCT_PAGE;
        case "standard":
          return SUPPORT_MESSAGE;
        case "business":
          return SUPPORT_MESSAGE;
        case "community":
          return MANAGER_CATEGORY;
        default:
          return PRODUCT_PAGE;
      }
    }
  }, [["method", "adminWizards", [_decorators.default]], ["method", "subscriptionLink", [(0, _decorators.default)("subscriptionClientInstalled")]], ["method", "subscriptionCtaLink", [(0, _decorators.default)("subscriptionType")]]]));
});