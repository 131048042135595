define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-topic-selector", ["exports", "select-kit/components/multi-select", "@ember/utils", "discourse/lib/search", "discourse-common/lib/helpers"], function (_exports, _multiSelect, _utils, _search, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    classNames: ["topic-selector", "wizard-topic-selector"],
    topics: null,
    value: [],
    content: [],
    nameProperty: "fancy_title",
    labelProperty: "title",
    titleProperty: "title",
    selectKitOptions: {
      clearable: true,
      filterable: true,
      filterPlaceholder: "choose_topic.title.placeholder",
      allowAny: false
    },
    didReceiveAttrs() {
      if (this.topics && !this.selectKit.hasSelection) {
        const values = (0, _helpers.makeArray)(this.topics.map(t => t.id));
        const content = (0, _helpers.makeArray)(this.topics);
        this.selectKit.change(values, content);
      }
      this._super(...arguments);
    },
    modifyComponentForRow() {
      return "topic-row";
    },
    search(filter) {
      if ((0, _utils.isEmpty)(filter)) {
        return [];
      }
      const searchParams = {};
      searchParams.typeFilter = "topic";
      searchParams.restrictToArchetype = "regular";
      searchParams.searchForId = true;
      if (this.category) {
        searchParams.searchContext = {
          type: "category",
          id: this.category
        };
      }
      return (0, _search.searchForTerm)(filter, searchParams).then(results => {
        if (results?.posts?.length > 0) {
          return results.posts.mapBy("topic");
        }
      });
    },
    actions: {
      onChange(value, items) {
        const content = items.map(t => {
          return {
            id: t.id,
            title: t.title,
            fancy_title: t.fancy_title,
            url: t.url
          };
        });
        this.setProperties({
          value,
          content
        });
        this.onChange(value, content);
      }
    }
  });
});