define("discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-json", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema", "@ember/object", "@ember/array"], function (_exports, _wizard, _wizardSchema, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildProperties = buildProperties;
  _exports.mapped = mapped;
  _exports.present = present;
  function present(val) {
    if (val === null || val === undefined) {
      return false;
    } else if (typeof val === "object") {
      return Object.keys(val).length !== 0;
    } else if (typeof val === "string" || val.constructor === Array) {
      return val && val.length;
    } else {
      return false;
    }
  }
  function mapped(property, type) {
    return _wizardSchema.default[type].mapped.indexOf(property) > -1;
  }
  function castCase(property, value) {
    return property.indexOf("_type") > -1 ? (0, _wizard.camelCase)(value) : value;
  }
  function buildMappedProperty(value) {
    let inputs = [];
    value.forEach(inputJson => {
      let input = {};
      Object.keys(inputJson).forEach(inputKey => {
        if (inputKey === "pairs") {
          let pairs = [];
          let pairCount = inputJson.pairs.length;
          inputJson.pairs.forEach(pairJson => {
            let pair = {};
            Object.keys(pairJson).forEach(pairKey => {
              pair[pairKey] = castCase(pairKey, pairJson[pairKey]);
            });
            pair.pairCount = pairCount;
            pairs.push(_object.default.create(pair));
          });
          input.pairs = pairs;
        } else {
          input[inputKey] = castCase(inputKey, inputJson[inputKey]);
        }
      });
      inputs.push(_object.default.create(input));
    });
    return (0, _array.A)(inputs);
  }
  function buildProperty(json, property, type, objectIndex) {
    let value = json[property];
    if (property === "index" && (value === null || value === undefined) && (objectIndex !== null || objectIndex !== undefined)) {
      return objectIndex;
    }
    if (!mapped(property, type) || !present(value) || !value.constructor === Array) {
      return value;
    }
    return buildMappedProperty(value);
  }
  function buildObject(json, type, objectIndex) {
    let props = {
      isNew: false
    };
    Object.keys(json).forEach(prop => {
      props[prop] = buildProperty(json, prop, type, objectIndex);
    });
    return _object.default.create(props);
  }
  function buildObjectArray(json, type) {
    let array = (0, _array.A)();
    if (present(json)) {
      json.forEach((objJson, objectIndex) => {
        let object = buildObject(objJson, type, objectIndex);
        array.pushObject(object);
      });
    }
    return array;
  }
  function buildBasicProperties(json, type, props) {
    let objectIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    (0, _wizard.listProperties)(type).forEach(p => {
      props[p] = buildProperty(json, p, type, objectIndex);
    });
    return props;
  }

  /// to be removed: necessary due to action array being moved from step to wizard
  function actionPatch(json) {
    let actions = json.actions || [];
    json.steps.forEach(step => {
      if (step.actions && step.actions.length) {
        step.actions.forEach(action => {
          action.run_after = "wizard_completion";
          actions.push(action);
        });
      }
    });
    json.actions = actions;
    return json;
  }
  ///

  function buildProperties(json) {
    let props = {
      steps: (0, _array.A)(),
      actions: (0, _array.A)()
    };
    if (present(json)) {
      props.existingId = true;
      props = buildBasicProperties(json, "wizard", props);
      if (present(json.steps)) {
        json.steps.forEach((stepJson, objectIndex) => {
          let stepProps = {
            isNew: false
          };
          stepProps = buildBasicProperties(stepJson, "step", stepProps, objectIndex);
          stepProps.fields = buildObjectArray(stepJson.fields, "field");
          props.steps.pushObject(_object.default.create(stepProps));
        });
      }
      json = actionPatch(json); // to be removed - see above

      props.actions = buildObjectArray(json.actions, "action");
    } else {
      (0, _wizard.listProperties)("wizard").forEach(prop => {
        props[prop] = _wizardSchema.default.wizard.basic[prop];
      });
    }
    return props;
  }
});