define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer", ["exports", "discourse-common/utils/decorators", "@ember/object", "@ember/component"], function (_exports, _decorators, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    showPreview: false,
    classNameBindings: [":wizard-field-composer", "showPreview:show-preview:hide-preview"],
    didInsertElement() {
      this.set("composer", _object.default.create({
        loading: false,
        reply: this.get("field.value") || ""
      }));
    },
    setField() {
      this.set("field.value", this.get("composer.reply"));
    },
    togglePreviewLabel(showPreview) {
      return showPreview ? "wizard_composer.hide_preview" : "wizard_composer.show_preview";
    },
    actions: {
      togglePreview() {
        this.toggleProperty("showPreview");
      },
      groupsMentioned() {},
      afterRefresh() {},
      cannotSeeMention() {},
      importQuote() {},
      showUploadSelector() {}
    }
  }, [["method", "setField", [(0, _decorators.observes)("composer.reply")]], ["method", "togglePreviewLabel", [(0, _decorators.default)("showPreview")]]]));
});