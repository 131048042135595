define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-step", ["exports", "discourse-common/utils/decorators", "@ember/component", "discourse-common/lib/get-url", "@ember/template", "@ember/runloop", "discourse/lib/text", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "@ember/object/computed", "discourse-common/lib/later", "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-composer-editor"], function (_exports, _decorators, _component, _getUrl, _template, _runloop, _text, _customWizard, _computed, _later, _customWizardComposerEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const uploadStartedEventKeys = ["upload-started"];
  const uploadEndedEventKeys = ["upload-success", "upload-error", "upload-cancelled", "uploads-cancelled", "uploads-aborted", "all-uploads-complete"];
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-step", "step.id"],
    saving: null,
    init() {
      this._super(...arguments);
      this.set("stylingDropdown", {});
    },
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _text.cook)(this.step.translatedTitle).then(cookedTitle => {
        this.set("cookedTitle", cookedTitle);
      });
      (0, _text.cook)(this.step.translatedDescription).then(cookedDescription => {
        this.set("cookedDescription", cookedDescription);
      });
      uploadStartedEventKeys.forEach(key => {
        this.appEvents.on(`${_customWizardComposerEditor.wizardComposerEdtiorEventPrefix}:${key}`, () => {
          this.set("uploading", true);
        });
      });
      uploadEndedEventKeys.forEach(key => {
        this.appEvents.on(`${_customWizardComposerEditor.wizardComposerEdtiorEventPrefix}:${key}`, () => {
          this.set("uploading", false);
        });
      });
    },
    didInsertElement() {
      this._super(...arguments);
      this.autoFocus();
    },
    showQuitButton: (index, required) => index === 0 && !required,
    showNextButton: (0, _computed.not)("step.final"),
    showDoneButton: (0, _computed.alias)("step.final"),
    btnsDisabled: (0, _computed.or)("saving", "uploading"),
    showFinishButton: (index, displayIndex, total, completed) => {
      return index !== 0 && displayIndex !== total && completed;
    },
    showBackButton: index => index > 0,
    bannerImage(src) {
      if (!src) {
        return;
      }
      return (0, _getUrl.default)(src);
    },
    bannerAndDescriptionClass(id) {
      return `wizard-banner-and-description wizard-banner-and-description-${id}`;
    },
    primaryButtonIndex(fields) {
      return fields.length + 1;
    },
    secondaryButtonIndex(fields) {
      return fields.length + 2;
    },
    _stepChanged() {
      this.set("saving", false);
      this.autoFocus();
    },
    _handleMessage: function () {
      const message = this.get("step.message");
      this.showMessage(message);
    },
    barStyle(displayIndex, totalSteps) {
      let ratio = parseFloat(displayIndex) / parseFloat(totalSteps - 1);
      if (ratio < 0) {
        ratio = 0;
      }
      if (ratio > 1) {
        ratio = 1;
      }
      return (0, _template.htmlSafe)(`width: ${ratio * 200}px`);
    },
    includeSidebar(fields) {
      return !!fields.findBy("show_in_sidebar");
    },
    autoFocus() {
      (0, _later.default)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          if ($(".invalid .wizard-focusable").length) {
            this.animateInvalidFields();
          }
        });
      });
    },
    animateInvalidFields() {
      (0, _runloop.schedule)("afterRender", () => {
        let $invalid = $(".invalid .wizard-focusable");
        if ($invalid.length) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $invalid.offset().top - 200
          }, 400);
        }
      });
    },
    advance() {
      this.set("saving", true);
      this.get("step").save().then(response => {
        (0, _customWizard.updateCachedWizard)(_customWizard.default.build(response["wizard"]));
        if (response["final"]) {
          _customWizard.default.finished(response);
        } else {
          this.goNext(response);
        }
      }).catch(() => this.animateInvalidFields()).finally(() => this.set("saving", false));
    },
    actions: {
      quit() {
        this.get("wizard").skip();
      },
      done() {
        this.send("nextStep");
      },
      showMessage(message) {
        this.sendAction(message);
      },
      stylingDropdownChanged(id, value) {
        this.set("stylingDropdown", {
          id,
          value
        });
      },
      exitEarly() {
        const step = this.step;
        step.validate();
        if (step.get("valid")) {
          this.set("saving", true);
          step.save().then(() => this.send("quit")).finally(() => this.set("saving", false));
        } else {
          this.autoFocus();
        }
      },
      backStep() {
        if (this.saving) {
          return;
        }
        this.goBack();
      },
      nextStep() {
        if (this.saving) {
          return;
        }
        this.step.validate();
        if (this.step.get("valid")) {
          this.advance();
        } else {
          this.autoFocus();
        }
      }
    }
  }, [["field", "showQuitButton", [(0, _decorators.default)("step.index", "wizard.required")]], ["field", "showFinishButton", [(0, _decorators.default)("step.index", "step.displayIndex", "wizard.totalSteps", "wizard.completed")]], ["field", "showBackButton", [(0, _decorators.default)("step.index")]], ["method", "bannerImage", [(0, _decorators.default)("step.banner")]], ["method", "bannerAndDescriptionClass", [(0, _decorators.default)("step.id")]], ["method", "primaryButtonIndex", [(0, _decorators.default)("step.fields.[]")]], ["method", "secondaryButtonIndex", [(0, _decorators.default)("step.fields.[]")]], ["method", "_stepChanged", [(0, _decorators.observes)("step.id")]], ["field", "_handleMessage", [(0, _decorators.observes)("step.message")]], ["method", "barStyle", [(0, _decorators.default)("step.index", "wizard.totalSteps")]], ["method", "includeSidebar", [(0, _decorators.default)("step.fields")]]]));
});