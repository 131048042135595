define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-user-chooser/wizard-user-chooser-row", ["exports", "select-kit/components/select-kit/select-kit-row"], function (_exports, _selectKitRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _selectKitRow.default.extend({
    classNames: ["user-row", "wizard-user-chooser-row"]
  });
});