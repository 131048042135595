define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-category-selector", ["exports", "select-kit/components/category-selector", "@ember/object", "discourse-common/lib/helpers"], function (_exports, _categorySelector, _object, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _categorySelector.default.extend({
    classNames: ["category-selector", "wizard-category-selector"],
    content: (0, _object.computed)("categoryIds.[]", "blacklist.[]", "whitelist.[]", function () {
      return this._super().filter(category => {
        const whitelist = (0, _helpers.makeArray)(this.whitelist);
        return !whitelist.length || whitelist.indexOf(category.id) > -1;
      });
    })
  });
});