define("discourse/plugins/discourse-custom-wizard/discourse/controllers/custom-wizard", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    queryParams: ["reset"]
  });
});