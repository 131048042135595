define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-logs", ["exports", "discourse/routes/discourse", "discourse/lib/ajax", "@ember/service"], function (_exports, _discourse, _ajax, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    model() {
      return (0, _ajax.ajax)(`/admin/wizards/wizard`);
    },
    setupController(controller, model) {
      const showParams = this.paramsFor("adminWizardsLogsShow");
      controller.setProperties({
        wizardId: showParams.wizardId,
        wizardList: model.wizard_list
      });
    },
    actions: {
      changeWizard(wizardId) {
        this.controllerFor("adminWizardsLogs").set("wizardId", wizardId);
        this.router.transitionTo("adminWizardsLogsShow", wizardId);
      }
    }
  });
});