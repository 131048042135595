define("discourse/plugins/discourse-custom-wizard/discourse/connectors/top-notices/prompt-completion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.custom_wizard_enabled && ctx.site.complete_custom_wizard;
    }
  };
});