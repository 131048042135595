define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-date-time-input", ["exports", "discourse/components/date-time-input", "discourse-common/utils/decorators"], function (_exports, _dateTimeInput, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dateTimeInput.default.extend(dt7948.p({
    classNameBindings: ["fieldClass"],
    timeTabindex(timeFirst, tabindex) {
      return timeFirst ? tabindex : tabindex + 1;
    },
    dateTabindex(timeFirst, tabindex) {
      return timeFirst ? tabindex + 1 : tabindex;
    }
  }, [["method", "timeTabindex", [(0, _decorators.default)("timeFirst", "tabindex")]], ["method", "dateTabindex", [(0, _decorators.default)("timeFirst", "tabindex")]]]));
});