define("discourse/plugins/discourse-custom-wizard/discourse/components/modal/next-session-scheduled", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="next-session-time-modal"
    @title={{this.title}}
  >
    <DateTimeInput
      @date={{this.bufferedDateTime}}
      @onChange={{action "dateTimeChanged"}}
      @showTime="true"
      @clearable="true"
    />
    <div class="modal-footer">
      <DButton
        @action={{action "submit"}}
        class="btn-primary"
        @label="admin.wizard.after_time_modal.done"
        @disabled={{this.submitDisabled}}
      />
    </div>
  </DModal>
  */
  {
    "id": "g+Hau1pv",
    "block": "[[[8,[39,0],[[24,0,\"next-session-time-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@date\",\"@onChange\",\"@showTime\",\"@clearable\"],[[30,0,[\"bufferedDateTime\"]],[28,[37,2],[[30,0],\"dateTimeChanged\"],null],\"true\",\"true\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,2],[[30,0],\"submit\"],null],\"admin.wizard.after_time_modal.done\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"date-time-input\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/modal/next-session-scheduled.hbs",
    "isStrictMode": false
  });
  class NextSessionScheduledComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "bufferedDateTime", [_tracking.tracked]))();
    #bufferedDateTime = (() => (dt7948.i(this, "bufferedDateTime"), void 0))();
    title = (() => _I18n.default.t("admin.wizard.after_time_modal.title"))();
    constructor() {
      super(...arguments);
      this.bufferedDateTime = this.args.model.dateTime ? moment(this.args.model.dateTime) : moment(Date.now());
    }
    get submitDisabled() {
      return moment().isAfter(this.bufferedDateTime);
    }
    submit() {
      const dateTime = this.bufferedDateTime;
      this.args.model.update(moment(dateTime).utc().toISOString());
      this.args.closeModal();
    }
    static #_2 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    dateTimeChanged(dateTime) {
      this.bufferedDateTime = dateTime;
    }
    static #_3 = (() => dt7948.n(this.prototype, "dateTimeChanged", [_object.action]))();
  }
  _exports.default = NextSessionScheduledComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NextSessionScheduledComponent);
});