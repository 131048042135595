define("discourse/plugins/discourse-custom-wizard/discourse/controllers/custom-wizard-index", ["exports", "@ember/controller", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _controller, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const reasons = {
    noWizard: "none",
    requiresLogin: "requires_login",
    notPermitted: "not_permitted",
    completed: "completed"
  };
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    noAccess: (0, _computed.or)("noWizard", "requiresLogin", "notPermitted", "completed"),
    noAccessI18nKey(reason) {
      return reason ? `wizard.${reasons[reason]}` : "wizard.none";
    },
    noAccessReason() {
      return Object.keys(reasons).find(reason => this.get(reason));
    }
  }, [["method", "noAccessI18nKey", [(0, _decorators.default)("noAccessReason")]], ["method", "noAccessReason", [_decorators.default]]]));
});