define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-pair", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "@ember/object/computed", "@ember/object", "@ember/component"], function (_exports, _wizardMapper, _computed, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNameBindings: [":mapper-pair", "hasConnector::no-connector"],
    firstPair: (0, _computed.gt)("pair.index", 0),
    showRemove: (0, _computed.alias)("firstPair"),
    showJoin: (0, _object.computed)("pair.pairCount", function () {
      return this.pair.index < this.pair.pairCount - 1;
    }),
    connectors: (0, _object.computed)(function () {
      return (0, _wizardMapper.connectorContent)("pair", this.inputType, this.options);
    })
  });
});