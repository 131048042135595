define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-text-editor", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/runloop", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _wizard, _runloop, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const excludedUserProperties = ["profile_background", "card_background"];
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "wizard-text-editor",
    barEnabled: true,
    previewEnabled: true,
    fieldsEnabled: true,
    hasWizardFields: (0, _computed.notEmpty)("wizardFieldList"),
    hasWizardActions: (0, _computed.notEmpty)("wizardActionList"),
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.barEnabled) {
        (0, _runloop.scheduleOnce)("afterRender", () => {
          $(this.element).find(".d-editor-button-bar").addClass("hidden");
        });
      }
    },
    previewLabel(forcePreview) {
      return _I18n.default.t("admin.wizard.editor.preview", {
        action: _I18n.default.t(`admin.wizard.editor.${forcePreview ? "hide" : "show"}`)
      });
    },
    popoverLabel(showPopover) {
      return _I18n.default.t("admin.wizard.editor.popover", {
        action: _I18n.default.t(`admin.wizard.editor.${showPopover ? "hide" : "show"}`)
      });
    },
    userPropertyList() {
      return _wizard.userProperties.filter(f => !excludedUserProperties.includes(f)).map(f => ` u{${f}}`);
    },
    wizardFieldList(wizardFields) {
      return (wizardFields || []).map(f => ` w{${f.id}}`);
    },
    wizardActionList(wizardActions) {
      return (wizardActions || []).map(a => ` w{${a.id}}`);
    },
    actions: {
      togglePreview() {
        this.toggleProperty("forcePreview");
      },
      togglePopover() {
        this.toggleProperty("showPopover");
      }
    }
  }, [["method", "previewLabel", [(0, _decorators.default)("forcePreview")]], ["method", "popoverLabel", [(0, _decorators.default)("showPopover")]], ["method", "userPropertyList", [(0, _decorators.default)()]], ["method", "wizardFieldList", [(0, _decorators.default)("wizardFields")]], ["method", "wizardActionList", [(0, _decorators.default)("wizardActions")]]]));
});