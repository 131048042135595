define("discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-submission", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatModel = formatModel;
  function formatModel(model) {
    let fields = [_object.default.create({
      id: "submitted_at",
      label: "Submitted At",
      enabled: true
    }), _object.default.create({
      id: "username",
      label: "User",
      enabled: true
    })];
    let submissions = [];
    model.submissions.forEach(s => {
      let submission = {
        submitted_at: s.submitted_at,
        username: s.user
      };
      Object.keys(s.fields).forEach(fieldId => {
        if (!fields.some(field => field.id === fieldId)) {
          fields.push(_object.default.create({
            id: fieldId,
            label: s.fields[fieldId].label,
            enabled: true
          }));
        }
        submission[fieldId] = s.fields[fieldId];
      });
      submissions.push(_object.default.create(submission));
    });
    return {
      fields,
      submissions
    };
  }
});