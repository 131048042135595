define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-custom-field", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/mixins/undo-changes", "@ember/component", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema"], function (_exports, _decorators, _computed, _object, _wizard, _undoChanges, _component, _wizardSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_undoChanges.default, dt7948.p({
    componentType: "field",
    classNameBindings: [":wizard-custom-field", "visible"],
    visible: (0, _object.computed)("currentFieldId", function () {
      return this.field.id === this.currentFieldId;
    }),
    isDropdown: (0, _computed.equal)("field.type", "dropdown"),
    isUpload: (0, _computed.equal)("field.type", "upload"),
    isCategory: (0, _computed.equal)("field.type", "category"),
    isTopic: (0, _computed.equal)("field.type", "topic"),
    isGroup: (0, _computed.equal)("field.type", "group"),
    isTag: (0, _computed.equal)("field.type", "tag"),
    isText: (0, _computed.equal)("field.type", "text"),
    isTextarea: (0, _computed.equal)("field.type", "textarea"),
    isUrl: (0, _computed.equal)("field.type", "url"),
    isComposer: (0, _computed.equal)("field.type", "composer"),
    showPrefill: (0, _computed.or)("isText", "isCategory", "isTag", "isGroup", "isDropdown", "isTopic"),
    showContent: (0, _computed.or)("isCategory", "isTag", "isGroup", "isDropdown", "isTopic"),
    showLimit: (0, _computed.or)("isCategory", "isTag", "isTopic"),
    isTextType: (0, _computed.or)("isText", "isTextarea", "isComposer"),
    isComposerPreview: (0, _computed.equal)("field.type", "composer_preview"),
    categoryPropertyTypes: (0, _wizard.selectKitContent)(["id", "slug"]),
    messageUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/field-settings",
    validations(type) {
      const applicableToField = [];
      for (let validation in _wizardSchema.default.field.validations) {
        if (_wizardSchema.default.field.validations[validation]["types"].includes(type)) {
          applicableToField.push(validation);
        }
      }
      return applicableToField;
    },
    isDateTime(type) {
      return ["date_time", "date", "time"].indexOf(type) > -1;
    },
    messageKey(type) {
      let key = "type";
      if (type) {
        key = "edit";
      }
      return key;
    },
    setupTypeOutput(fieldType, options) {
      const selectionType = {
        category: "category",
        tag: "tag",
        group: "group"
      }[fieldType];
      if (selectionType) {
        options[`${selectionType}Selection`] = "output";
        options.outputDefaultSelection = selectionType;
      }
      return options;
    },
    contentOptions(fieldType) {
      let options = {
        wizardFieldSelection: true,
        textSelection: "key,value",
        userFieldSelection: "key,value",
        context: "field"
      };
      options = this.setupTypeOutput(fieldType, options);
      if (this.isDropdown) {
        options.wizardFieldSelection = "key,value";
        options.userFieldOptionsSelection = "output";
        options.textSelection = "key,value";
        options.inputTypes = "association,conditional,assignment";
        options.pairConnector = "association";
        options.keyPlaceholder = "admin.wizard.key";
        options.valuePlaceholder = "admin.wizard.value";
      }
      return options;
    },
    prefillOptions(fieldType) {
      let options = {
        wizardFieldSelection: true,
        textSelection: true,
        userFieldSelection: "key,value",
        context: "field"
      };
      return this.setupTypeOutput(fieldType, options);
    },
    fieldConditionOptions(stepIndex) {
      const options = {
        inputTypes: "validation",
        context: "field",
        textSelection: "value",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options.wizardFieldSelection = true;
        options.wizardActionSelection = true;
      }
      return options;
    },
    fieldIndexOptions(stepIndex) {
      const options = {
        context: "field",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options.wizardFieldSelection = true;
        options.wizardActionSelection = true;
      }
      return options;
    },
    actions: {
      imageUploadDone(upload) {
        this.setProperties({
          "field.image": upload.url,
          "field.image_upload_id": upload.id
        });
      },
      imageUploadDeleted() {
        this.setProperties({
          "field.image": null,
          "field.image_upload_id": null
        });
      },
      changeCategory(category) {
        this.set("field.category", category?.id);
      }
    }
  }, [["method", "validations", [(0, _decorators.default)("field.type")]], ["method", "isDateTime", [(0, _decorators.default)("field.type")]], ["method", "messageKey", [(0, _decorators.default)("field.type")]], ["method", "contentOptions", [(0, _decorators.default)("field.type")]], ["method", "prefillOptions", [(0, _decorators.default)("field.type")]], ["method", "fieldConditionOptions", [(0, _decorators.default)("step.index")]], ["method", "fieldIndexOptions", [(0, _decorators.default)("step.index")]]]));
});