define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-category", ["exports", "discourse-common/utils/decorators", "discourse/models/category", "@ember/component"], function (_exports, _decorators, _category, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    categories: [],
    didInsertElement() {
      const property = this.field.property || "id";
      const value = this.field.value;
      if (value) {
        this.set("categories", [...value].reduce((result, v) => {
          let val = property === "id" ? _category.default.findById(v) : _category.default.findBySlug(v);
          if (val) {
            result.push(val);
          }
          return result;
        }, []));
      }
    },
    setValue() {
      const categories = (this.categories || []).filter(c => !!c);
      const property = this.field.property || "id";
      if (categories.length) {
        this.set("field.value", categories.reduce((result, c) => {
          if (c && c[property]) {
            result.push(c[property]);
          }
          return result;
        }, []));
      }
    }
  }, [["method", "setValue", [(0, _decorators.observes)("categories")]]]));
});