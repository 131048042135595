define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-value-list", ["exports", "admin/components/value-list"], function (_exports, _valueList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _valueList.default.extend({
    _saveValues() {
      if (this.inputType === "array") {
        this.onChange(this.collection);
        return;
      }
      this.onChange(this.collection.join(this.inputDelimiter || "\n"));
    }
  });
});