define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer-preview", ["exports", "@ember/component", "discourse/lib/load-oneboxes", "@ember/runloop", "discourse-common/lib/debounce", "pretty-text/upload-short-url", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _component, _loadOneboxes, _runloop, _debounce, _uploadShortUrl, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    updatePreview() {
      if (this.isDestroyed) {
        return;
      }
      (0, _runloop.schedule)("afterRender", () => {
        if (this._state !== "inDOM" || !this.element) {
          return;
        }
        const $preview = $(this.element);
        if ($preview.length === 0) {
          return;
        }
        this.previewUpdated($preview);
      });
    },
    previewUpdated($preview) {
      // Paint oneboxes
      const paintFunc = () => {
        (0, _loadOneboxes.loadOneboxes)($preview[0], _ajax.ajax, null, null, this.siteSettings.max_oneboxes_per_post, true // refresh on every load
        );
      };
      (0, _debounce.default)(this, paintFunc, 450);

      // Short upload urls need resolution
      (0, _uploadShortUrl.resolveAllShortUrls)(_ajax.ajax, this.siteSettings, $preview[0]);
    }
  }, [["method", "updatePreview", [(0, _decorators.on)("init")]]]));
});