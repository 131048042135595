define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-connector", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "@ember/runloop", "I18n"], function (_exports, _component, _computed, _object, _wizardMapper, _runloop, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNameBindings: [":mapper-connector", ":mapper-block", "hasMultiple::single"],
    hasMultiple: (0, _computed.gt)("connectors.length", 1),
    connectorLabel: (0, _object.computed)(function () {
      let key = this.connector;
      let path = this.inputTypes ? `input.${key}.name` : `connector.${key}`;
      return _I18n.default.t(`admin.wizard.${path}`);
    }),
    didReceiveAttrs() {
      if (!this.connector) {
        (0, _runloop.later)(() => {
          this.set("connector", (0, _wizardMapper.defaultConnector)(this.connectorType, this.inputType, this.options));
        });
      }
    },
    actions: {
      changeConnector(value) {
        this.set("connector", value);
        this.onUpdate("connector", this.connectorType);
      }
    }
  });
});