define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-custom-field", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "@ember/utils"], function (_exports, _ajax, _ajaxError, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomWizardCustomField = _object.default.extend({
    isNew: (0, _utils.isEmpty)("id")
  });
  const basePath = "/admin/wizards/custom-fields";
  CustomWizardCustomField.reopenClass({
    listFields() {
      return (0, _ajax.ajax)(basePath).catch(_ajaxError.popupAjaxError);
    },
    saveField(customField) {
      return (0, _ajax.ajax)(basePath, {
        type: "PUT",
        data: {
          custom_field: customField
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    destroyField(field) {
      return (0, _ajax.ajax)(`${basePath}/${field.name}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = CustomWizardCustomField;
});