define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards", ["exports", "discourse/routes/discourse", "@ember/service"], function (_exports, _discourse, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    afterModel(model, transition) {
      if (transition.targetName === "adminWizards.index") {
        this.router.transitionTo("adminWizardsWizard");
      }
    }
  });
});