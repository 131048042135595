define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-tag-chooser", ["exports", "select-kit/components/tag-chooser"], function (_exports, _tagChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tagChooser.default.extend({
    searchTags(url, data, callback) {
      if (this.tagGroups) {
        let tagGroupsString = this.tagGroups.join(",");
        data.filterForInput = {
          name: "custom-wizard-tag-chooser",
          groups: tagGroupsString
        };
      }
      return this._super(url, data, callback);
    }
  });
});