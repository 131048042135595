define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-selector/wizard-subscription-selector-row", ["exports", "select-kit/components/select-kit/select-kit-row", "discourse-common/utils/decorators"], function (_exports, _selectKitRow, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _selectKitRow.default.extend(dt7948.p({
    classNameBindings: ["isDisabled:disabled"],
    isDisabled() {
      return this.item.disabled;
    },
    click(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.item.disabled) {
        this.selectKit.select(this.rowValue, this.item);
      }
      return false;
    }
  }, [["method", "isDisabled", [(0, _decorators.default)("item")]]]));
});