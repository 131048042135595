define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topics", ["exports", "@ember/component", "@ember/runloop", "discourse-common/utils/decorators"], function (_exports, _component, _runloop, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["wizard-similar-topics"],
    showTopics: true,
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      if (this._state === "destroying") {
        return;
      }
      let $target = $(e.target);
      if (!$target.hasClass("show-topics")) {
        this.set("showTopics", false);
      }
    },
    toggleShowWhenTopicsChange() {
      this.set("showTopics", true);
    },
    actions: {
      toggleShowTopics() {
        this.set("showTopics", true);
      }
    }
  }, [["method", "toggleShowWhenTopicsChange", [(0, _decorators.observes)("topics")]]]));
});