define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-text", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    keyPress(e) {
      e.stopPropagation();
    }
  });
});