define("discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/top-notices/prompt-completion", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each site.complete_custom_wizard as |wizard|}}
    <div class="row">
      <div class="alert alert-info alert-wizard">
        <a href={{wizard.url}}>{{i18n
            "wizard.complete_custom"
            name=wizard.name
          }}</a>
      </div>
    </div>
  {{/each}}
  */
  {
    "id": "a7Ykcl9K",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"site\",\"complete_custom_wizard\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-info alert-wizard\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"url\"]]],[12],[1,[28,[35,2],[\"wizard.complete_custom\"],[[\"name\"],[[30,1,[\"name\"]]]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `site` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/top-notices/prompt-completion.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.site}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"wizard\"],false,[\"each\",\"-track-array\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/top-notices/prompt-completion.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});