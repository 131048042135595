define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-field", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-step", "discourse/lib/url"], function (_exports, _object, _ajax, _ajaxError, _decorators, _getUrl, _customWizardField, _customWizardStep, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findCustomWizard = findCustomWizard;
  _exports.getCachedWizard = getCachedWizard;
  _exports.updateCachedWizard = updateCachedWizard;
  const CustomWizard = _object.default.extend(dt7948.p({
    totalSteps: length => length,
    skip() {
      if (this.required && !this.completed && this.permitted) {
        return;
      }
      CustomWizard.skip(this.id);
    },
    restart() {
      CustomWizard.restart(this.id);
    }
  }, [["field", "totalSteps", [(0, _decorators.default)("steps.length")]]]));
  CustomWizard.reopenClass({
    skip(wizardId) {
      (0, _ajax.ajax)({
        url: `/w/${wizardId}/skip`,
        type: "PUT"
      }).then(result => {
        CustomWizard.finished(result);
      }).catch(_ajaxError.popupAjaxError);
    },
    restart(wizardId) {
      (0, _ajax.ajax)({
        url: `/w/${wizardId}/skip`,
        type: "PUT"
      }).then(() => {
        _url.default.redirectTo((0, _getUrl.default)(`/w/${wizardId}`));
      }).catch(_ajaxError.popupAjaxError);
    },
    finished(result) {
      let url = "/";
      if (result.redirect_on_complete) {
        url = result.redirect_on_complete;
      }
      _url.default.redirectTo((0, _getUrl.default)(url));
    },
    build(wizardJson) {
      if (!wizardJson) {
        return null;
      }
      if (!wizardJson.completed && wizardJson.steps) {
        wizardJson.steps = wizardJson.steps.map(step => {
          const stepObj = _customWizardStep.default.create(step);
          stepObj.wizardId = wizardJson.id;
          stepObj.fields.sort((a, b) => {
            return parseFloat(a.number) - parseFloat(b.number);
          });
          let tabindex = 1;
          stepObj.fields.forEach(f => {
            f.tabindex = tabindex;
            if (["date_time"].includes(f.type)) {
              tabindex = tabindex + 2;
            } else {
              tabindex++;
            }
          });
          stepObj.fields = stepObj.fields.map(f => {
            f.wizardId = wizardJson.id;
            f.stepId = stepObj.id;
            return _customWizardField.default.create(f);
          });
          return stepObj;
        }).sort((a, b) => {
          return parseFloat(a.index) - parseFloat(b.index);
        });
      }
      return CustomWizard.create(wizardJson);
    }
  });
  function findCustomWizard(wizardId) {
    let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let url = `/w/${wizardId}.json`;
    let paramKeys = Object.keys(params).filter(k => {
      if (k === "wizard_id") {
        return false;
      }
      return !!params[k];
    });
    if (paramKeys.length) {
      url += "?";
      paramKeys.forEach((k, i) => {
        if (i > 0) {
          url += "&";
        }
        url += `${k}=${params[k]}`;
      });
    }
    return (0, _ajax.ajax)(url).then(result => {
      return CustomWizard.build(result);
    });
  }
  let _wizard_store;
  function updateCachedWizard(wizard) {
    _wizard_store = wizard;
  }
  function getCachedWizard() {
    return _wizard_store;
  }
  var _default = _exports.default = CustomWizard;
});