define("discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", ["exports", "@ember/object", "@ember/array", "I18n"], function (_exports, _object, _array, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.connectorContent = connectorContent;
  _exports.defaultConnector = defaultConnector;
  _exports.defaultInputType = defaultInputType;
  _exports.defaultSelectionType = defaultSelectionType;
  _exports.inputTypesContent = inputTypesContent;
  _exports.newInput = newInput;
  _exports.newPair = newPair;
  _exports.selectionTypes = void 0;
  // Inputs

  const selectableInputTypes = ["conditional", "assignment", "association", "validation"];
  function defaultInputType() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return options.inputTypes.split(",")[0];
  }
  function mapInputTypes(types) {
    return types.map(function (type) {
      return {
        id: type,
        name: _I18n.default.t(`admin.wizard.input.${type}.name`)
      };
    });
  }
  function inputTypesContent() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return options.inputTypes ? mapInputTypes(options.inputTypes.split(",")) : mapInputTypes(selectableInputTypes);
  }

  // connectorTypes

  const connectors = {
    pair: ["equal", "not_equal", "greater", "less", "greater_or_equal", "less_or_equal", "regex", "is"],
    output: ["then", "set"]
  };
  function defaultConnector(connectorType, inputType) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (connectorType === "input") {
      return defaultInputType(options);
    }
    if (connectorType === "pair") {
      if (inputType === "conditional") {
        return "equal";
      }
      if (inputType === "association") {
        return "association";
      }
      if (inputType === "validation") {
        return "equal";
      }
    }
    if (connectorType === "output") {
      if (inputType === "conditional") {
        return "then";
      }
      if (inputType === "assignment") {
        return "set";
      }
    }
    return "equal";
  }
  function connectorContent(connectorType, inputType, opts) {
    let connector = opts[`${connectorType}Connector`];
    if (!connector && connectorType === "output" || inputType === "association") {
      connector = defaultConnector(connectorType, inputType);
    }
    let content = connector ? [connector] : connectors[connectorType];
    return content.map(function (item) {
      return {
        id: item,
        name: _I18n.default.t(`admin.wizard.connector.${item}`)
      };
    });
  }

  // Selectors

  const selectionTypes = _exports.selectionTypes = ["text", "list", "wizardField", "wizardAction", "userField", "userFieldOptions", "group", "category", "tag", "user", "customField", "value"];
  function defaultSelectionType(inputType) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let connector = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (options[`${inputType}DefaultSelection`]) {
      return options[`${inputType}DefaultSelection`];
    }
    if (connector === "is") {
      return "value";
    }
    let type = selectionTypes[0];
    for (let t of selectionTypes) {
      let inputTypes = options[`${t}Selection`];
      if (inputTypes === true || typeof inputTypes === "string" && inputTypes.split(",").indexOf(inputType) > -1) {
        type = t;
        break;
      }
    }
    return type;
  }

  // items

  function newPair(inputType) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let params = {
      index: options.index,
      pairCount: options.pairCount,
      key: "",
      key_type: defaultSelectionType("key", options),
      value: "",
      value_type: defaultSelectionType("value", options),
      connector: defaultConnector("pair", inputType, options)
    };
    return _object.default.create(params);
  }
  function newInput() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let count = arguments.length > 1 ? arguments[1] : undefined;
    const inputType = defaultInputType(options);
    let params = {
      type: inputType,
      pairs: (0, _array.A)([newPair(inputType, Object.assign({}, options, {
        index: 0,
        pairCount: 1
      }))])
    };
    if (count > 0) {
      params.connector = options.inputConnector;
    }
    if (["conditional", "assignment"].indexOf(inputType) > -1 || options.outputDefaultSelection || options.outputConnector) {
      params["output_type"] = defaultSelectionType("output", options);
      params["output_connector"] = defaultConnector("output", inputType, options);
    }
    return _object.default.create(params);
  }
});