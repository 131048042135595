define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-mapper-selector-type", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{item.label}}
  */
  {
    "id": "NFyTCB1s",
    "block": "[[[1,[30,0,[\"item\",\"label\"]]],[1,[28,[32,0],[\"[[\\\"The `item` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-mapper-selector-type.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.item}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-mapper-selector-type.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});