define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field", ["exports", "@ember/component", "@ember/string", "discourse-common/utils/decorators", "discourse/lib/text"], function (_exports, _component, _string, _decorators, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-field", "typeClasses", "field.invalid", "field.id"],
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _text.cook)(this.field.translatedDescription).then(cookedDescription => {
        this.set("cookedDescription", cookedDescription);
      });
    },
    typeClasses: (type, id) => `${(0, _string.dasherize)(type)}-field ${(0, _string.dasherize)(type)}-${(0, _string.dasherize)(id)}`,
    fieldClass: id => `field-${(0, _string.dasherize)(id)} wizard-focusable`,
    inputComponentName(type, id) {
      if (["text_only"].includes(type)) {
        return false;
      }
      return (0, _string.dasherize)(type === "component" ? id : `custom-wizard-field-${type}`);
    },
    textType(fieldType) {
      return ["text", "textarea"].includes(fieldType);
    }
  }, [["field", "typeClasses", [(0, _decorators.default)("field.type", "field.id")]], ["field", "fieldClass", [(0, _decorators.default)("field.id")]], ["method", "inputComponentName", [(0, _decorators.default)("field.type", "field.id")]], ["method", "textType", [(0, _decorators.default)("field.type")]]]));
});