define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    subscription: (0, _service.inject)(),
    showApi: (0, _computed.or)("subscription.businessSubscription", "subscription.communitySubscription")
  });
});