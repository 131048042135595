define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-selector/wizard-subscription-selector-header", ["exports", "select-kit/components/select-kit/single-select-header", "@ember/object", "@ember/object/computed"], function (_exports, _singleSelectHeader, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _singleSelectHeader.default.extend({
    classNames: ["combo-box-header", "wizard-subscription-selector-header"],
    caretUpIcon: (0, _computed.reads)("selectKit.options.caretUpIcon"),
    caretDownIcon: (0, _computed.reads)("selectKit.options.caretDownIcon"),
    caretIcon: (0, _object.computed)("selectKit.isExpanded", "caretUpIcon", "caretDownIcon", function () {
      return this.selectKit.isExpanded ? this.caretUpIcon : this.caretDownIcon;
    })
  });
});