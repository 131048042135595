define("discourse/plugins/discourse-custom-wizard/discourse/mixins/valid-state", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.States = void 0;
  const States = _exports.States = {
    UNCHECKED: 0,
    INVALID: 1,
    VALID: 2
  };
  var _default = _exports.default = dt7948.p({
    _validState: null,
    errorDescription: null,
    init() {
      this._super(...arguments);
      this.set("_validState", States.UNCHECKED);
    },
    valid: state => state === States.VALID,
    invalid: state => state === States.INVALID,
    unchecked: state => state === States.UNCHECKED,
    setValid(valid, description) {
      this.set("_validState", valid ? States.VALID : States.INVALID);
      if (!valid && description && description.length) {
        this.set("errorDescription", description);
      } else {
        this.set("errorDescription", null);
      }
    }
  }, [["field", "valid", [(0, _decorators.default)("_validState")]], ["field", "invalid", [(0, _decorators.default)("_validState")]], ["field", "unchecked", [(0, _decorators.default)("_validState")]]]);
});