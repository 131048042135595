define("discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-api", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-wizard-controls">
    {{combo-box
      value=apiName
      content=apiList
      onChange=(route-action "changeApi")
      options=(hash none="admin.wizard.api.select")
    }}
  
    {{d-button
      action=(route-action "createApi")
      label="admin.wizard.api.create"
      icon="plus"
    }}
  </div>
  
  <div class="admin-wizard-container">
    {{outlet}}
  </div>
  */
  {
    "id": "8pvBwDog",
    "block": "[[[10,0],[14,0,\"admin-wizard-controls\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"content\",\"onChange\",\"options\"],[[30,0,[\"apiName\"]],[30,0,[\"apiList\"]],[28,[37,1],[\"changeApi\"],null],[28,[37,2],null,[[\"none\"],[\"admin.wizard.api.select\"]]]]]]],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"action\",\"label\",\"icon\"],[[28,[37,1],[\"createApi\"],null],\"admin.wizard.api.create\",\"plus\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-wizard-container\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `apiName` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-api.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.apiName}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `apiList` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-api.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.apiList}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"combo-box\",\"route-action\",\"hash\",\"d-button\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-api.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});