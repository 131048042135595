define("discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", ["exports", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema", "I18n"], function (_exports, _object, _wizardSchema, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelCase = camelCase;
  _exports.generateId = generateId;
  _exports.generateName = generateName;
  _exports.listProperties = listProperties;
  _exports.notificationLevels = void 0;
  _exports.selectKitContent = selectKitContent;
  _exports.sentenceCase = sentenceCase;
  _exports.snakeCase = snakeCase;
  _exports.translationOrText = translationOrText;
  _exports.userProperties = void 0;
  _exports.wizardFieldList = wizardFieldList;
  function selectKitContent(content) {
    return content.map(i => ({
      id: i,
      name: i
    }));
  }
  function generateName(id) {
    return id ? sentenceCase(id) : "";
  }
  function generateId(name) {
    return name ? snakeCase(name) : "";
  }
  function sentenceCase(string) {
    return string.replace(/[_\-]+/g, " ").toLowerCase().replace(/(^\w|\b\w)/g, m => m.toUpperCase());
  }
  function snakeCase(string) {
    return string.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join("_");
  }
  function camelCase(string) {
    return string.replace(/([-_][a-z])/gi, $1 => {
      return $1.toUpperCase().replace("-", "").replace("_", "");
    });
  }
  function translationOrText(i18nKey, text) {
    return _I18n.default.findTranslation(i18nKey) ? _I18n.default.t(i18nKey) : text;
  }
  const userProperties = _exports.userProperties = ["name", "username", "email", "avatar", "date_of_birth", "title", "profile_background", "card_background", "locale", "location", "website", "bio_raw", "trust_level", "email_level", "email_messages_level", "email_digests"];
  const notificationLevels = _exports.notificationLevels = ["regular", "watching", "tracking", "watching_first_post", "muted"];
  function listProperties(itemType) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let properties = Object.keys(_wizardSchema.default[itemType].basic);
    const types = _wizardSchema.default[itemType].types;
    if (types) {
      let typeProperties = [];
      if (opts.allTypes) {
        Object.keys(types).forEach(type => {
          typeProperties = typeProperties.concat(Object.keys(types[type]));
        });
      } else if (opts.objectType && types[opts.objectType]) {
        typeProperties = Object.keys(types[opts.objectType]);
      }
      properties = properties.concat(typeProperties);
    }
    return properties;
  }
  function wizardFieldList() {
    let steps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let upToIndex = null;
    if (opts.upTo) {
      upToIndex = steps.map(s => s.id).indexOf(opts.upTo);
    }
    return steps.reduce((result, step, index) => {
      let fields = step.fields;
      if (fields && fields.length > 0) {
        if (upToIndex === null || index < upToIndex) {
          result.push(...fields.map(field => {
            return _object.default.create({
              id: field.id,
              label: `${field.label} (${field.id})`,
              type: field.type
            });
          }));
        }
      }
      return result;
    }, []);
  }
});