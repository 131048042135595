define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-logs", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    documentationUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/",
    wizardName(wizardId) {
      let currentWizard = this.wizardList.find(wizard => wizard.id === wizardId);
      if (currentWizard) {
        return currentWizard.name;
      }
    },
    messageOpts(wizardName) {
      return {
        wizardName
      };
    },
    messageKey(wizardId) {
      let key = "select";
      if (wizardId) {
        key = "viewing";
      }
      return key;
    }
  }, [["method", "wizardName", [(0, _decorators.default)("wizardId")]], ["method", "messageOpts", [(0, _decorators.default)("wizardName")]], ["method", "messageKey", [(0, _decorators.default)("wizardId")]]]));
});