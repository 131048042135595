define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-message", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const icons = {
    error: "times-circle",
    success: "check-circle",
    warn: "exclamation-circle",
    info: "info-circle"
  };
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-message", "type", "loading"],
    showDocumentation: (0, _computed.not)("loading"),
    showIcon: (0, _computed.not)("loading"),
    hasItems: (0, _computed.notEmpty)("items"),
    icon(type) {
      return icons[type] || "info-circle";
    },
    message(key, component, opts) {
      return _I18n.default.t(`admin.wizard.message.${component}.${key}`, opts || {});
    },
    documentation(component) {
      return _I18n.default.t(`admin.wizard.message.${component}.documentation`);
    }
  }, [["method", "icon", [(0, _decorators.default)("type")]], ["method", "message", [(0, _decorators.default)("key", "component", "opts")]], ["method", "documentation", [(0, _decorators.default)("component")]]]));
});